<template>
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
    <div class="col-xl-6 col-lg-6 col-md-8 col-12">
      <div class="ls-center">
        <h3>Recharge DialFrom263 Account</h3>
      </div>
      <div class="ls-center">
        <p v-if="!auth.isAuthenticated">Please login first to add credit to your calling app.</p>
        <div v-if="!auth.isAuthenticated" class="row">
          <div class="col">
            <button @click="recharge()" class="m-3 ps-btn btn-sm">
              Add diaspora talk credit</button>
          </div>
          <div class="col">
            <a href="https://play.google.com/store/apps/details?id=sc.org.pahukama&hl=tw&pli=1" class="m-3 ps-btn btn-sm">
              Download Android Apps</a>
          </div>
          <div class="col">
            <a href="https://apps.apple.com/us/app/pahukama/id1645729512" class="m-3 ps-btn btn-sm">
              Download Iphone Apps</a>
          </div>
        </div>
        <p v-if="!auth.isAuthenticated">Signin/Login for the Best Recharge Experience</p>
      </div>
      <form class="ls-form">
        <div class="form-group">
          <label for="email-input">DialFrom263 Email Account</label>
          <input disabled type="email" class="form-control" id="email-input" aria-describedby="emailHelp"
            v-model="form.email" />
        </div>
        <div class="form-group">
          <label for="voucherShop">Select Recharge Amount</label>
          <select :disabled="!auth.isAuthenticated" class="form-control" id="voucherShop" v-model="form.amount">
            <option selected value="1">$1</option>
            <option value="2">$2</option>
            <option value="5">$5</option>
            <option value="10">$10</option>
            <option value="20">$20</option>
            <option value="50">$50</option>
          </select>
          <small v-if="errors && errors.amount" class="mobile_error text-danger">
            {{ errors.amount }}
          </small>
        </div>
        <div class="form-group">
          <label for="phone-input">Your DiasporaTalk Account Number</label>
          <input :disabled="!auth.isAuthenticated" type="phone" class="form-control" id="phone-input"
            v-model="form.phone" />
          <small v-if="errors && errors.phone" class="mobile_error text-danger">
            {{ errors.phone }}
          </small>
        </div>
        <div v-if="auth.isAuthenticated" class="form-group form-check">
          <input required type="checkbox" class="form-check-input" id="agreeToTerms" v-model="agreeToTerms" />
          <label class="form-check-label" for="agreeToTerms">
            I have Read the Terms and Agreements</label>
          <small v-if="errors && errors.terms" class="terms_error text-danger">
            {{ errors.terms }}
          </small>
        </div>
        <div class="justify-content-between mb-4 mt-4">
          <div>
            &nbsp;&nbsp;
            <button v-if="auth.isAuthenticated" :disabled="isLoading" type="submit"
              class="btn btn-primary ls-submit-button" @click.prevent="addToCart()">
              <i v-if="!isLoading" class="icon-cart-add"></i>
              {{ isLoading ? "Processing..." : "Add to cart" }}
              <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
              <div v-if="isLoading" class="spinner-border text-light" role="status"></div>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-2 col-12"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "DialFrom263",
  data() {
    return {
      countries: [],
      form: {
        email: "",
        amount: "",
        phone: "",
        country: 234,
        dialing_code: 263,
      },
      agreeToTerms: false,
      isLoading: false,
      voucherShop: "",
      errors: {
        amount: "",
        phone: "",
        terms: "",
      },
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.setUpAccountDetails();
  },
  methods: {
    setUpAccountDetails() {
      if (this.auth.isAuthenticated) {
        this.form.email = this.auth.user.email;
        this.form.phone = this.auth.user.phone_number;
      }
    },

    isValid() {
      let isValidForm = true;

      this.errors.amount = "";
      this.errors.phone = "";
      this.errors.terms = "";

      if (this.form.amount === "") {
        this.errors["amount"] = "Airtime amount is required";
        isValidForm = false;
      }
      if (this.form.phone === "") {
        this.errors["phone"] = "Phone number is required";
        isValidForm = false;
      }
      if (!this.agreeToTerms) {
        this.errors["terms"] = "Please agree to terms";
        isValidForm = false;
      }
      return isValidForm;
    },

    addToCart() {
      if (this.isValid()) {
        // this.form.phone = this.form.dialing_code + this.form.phone.replace(/^0+/, '');
        // var lengthOfZip = ("" + this.form.dialing_code).length;
        // var startOfPhone = ("" + this.form.phone).slice(0, lengthOfZip);

        // let phone = this.form.dialing_code + this.form.phone.replace(startOfPhone, '');
        // return console.log(lengthOfZip, this.form.phone, startOfPhone, phone);
        this.form.phone = this.form.phone.replace(/^0+/, "");
        let voucherName = "Airtime for Dial From 263";
        const quantity = 1;
        const product = {
          id: this.generateUUID(),
          name: voucherName,
          quantity: this.form.amount,
          phone: this.form.phone,
          price: this.form.amount,
          total: this.form.amount * quantity,
          type: "duri-airtime",
          shop: 2,
        };

        console.log("DialFrom263");
        console.log(product);
        this.$store.dispatch("cart/addToCart", {
          product: product,
          quantity: 1,
        });

        // this.$toasted.success("DialFrom263 added to cart");
        this.$toasted.show("DialFrom263 added to cart", {
          duration: 20000,
          action: [
            {
              text: "Checkout",
              push: {
                name: "cart",
              },
            },
            {
              text: "Continue shopping",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          ],
        });
      }
    },

    generateUUID() {
      // Public Domain/MIT
      let d = new Date().getTime(); //Timestamp
      let d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    recharge() {
      this.$store.commit("auth/next", "dial_from_263");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
